import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
import { ArrayElement, PhaseLabel } from "powerlinks-common";
import { Button } from "@mui/material";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react"
import { useEffect, useState } from "react";
import { getQueryKey } from "@trpc/react-query";
import { useQueryClient } from "@tanstack/react-query";

type SchemaRotationType = { connector: number, rotation: string };

const schema = {
    "type": "object",
    "properties": {
        "installation": {
            "type": "string",
            "enum": ["3phase", "single"]
        },
        "phaseRotation": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "connector": {
                        "type": "number"
                    },
                    "rotation": {
                        "type": "string"
                    }
                }
            }
        }
    }
}

type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

type Params = {
    chargePoint: CP
}

const phaseToConfig = (rotation: string): Partial<Record<PhaseLabel, PhaseLabel>> => {
    if (rotation == undefined) {
        return {};
    }
    const phases = rotation.split(",");
    const keys: Array<PhaseLabel> = ["L1", "L2", "L3"];
    const ret: Partial<Record<PhaseLabel, PhaseLabel>> = {};
    for (let i = 0; i < phases.length; i++) {
        const key = keys[i];
        if (key != undefined) {
            ret[key] = phases[i] as PhaseLabel;
        }
    }
    return ret;
}

const connectorPhaseToConfig = (connectorPhases: Array<SchemaRotationType>): Record<number, Partial<Record<PhaseLabel, PhaseLabel>>> => {
    const ret: Record<number, Partial<Record<PhaseLabel, PhaseLabel>>> = {};
    if (connectorPhases != undefined) {
        for (const conPhase of connectorPhases) {
            ret[conPhase.connector] = phaseToConfig(conPhase.rotation);
        }
    }
    return ret;
}

const configToConnectorPhases = (cfg: Record<number, Partial<Record<PhaseLabel, PhaseLabel>>>): Array<SchemaRotationType> => {
    const ret: Array<SchemaRotationType> = [];
    if (cfg != undefined) {
        for (const [connector, rotationCfg] of Object.entries(cfg)) {
            ret.push({
                connector: parseInt(connector),
                rotation: configToPhase(rotationCfg)
            });
        }
    }
    return ret;
}

const configToPhase = (cfg: Partial<Record<PhaseLabel, PhaseLabel>>): string => {
    const keys: Array<PhaseLabel> = ["L1", "L2", "L3"];
    const ret = [];
    for (let i = 0; i < keys.length; i++) {
        const rotated = cfg[keys[i]];
        if (rotated != undefined) {
            ret.push(rotated);
        } else {
            break;
        }
    }
    return ret.join(",");
}

export const PhaseConfig = ({ chargePoint }: Params) => {
    const configQ = trpc.cpDebug.getConfig.useQuery(chargePoint.cpId);
    const queryClient = useQueryClient();
    const updateConfigM = trpc.cpDebug.updateConfig.useMutation({
        onSuccess: () => {
            const queryKey = getQueryKey(trpc.cpDebug.getConfig);
            queryClient.invalidateQueries(queryKey);
        }
    });
    const [data, setData] = useState<any>();
    useEffect(() => {
        if (configQ.data != undefined) {
            console.log(configQ.data);
            const { phaseRotation, ...rest } = configQ.data;
            setData({
                phaseRotation: configToConnectorPhases(phaseRotation),
                ...rest
            });
        }
    }, [configQ.data]);

    return (
        <div style={{
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end'
        }}>
            <JsonForms
                schema={schema}
                data={data}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={(e) => {
                    setData(e.data);
                }} />
            <Button onClick={(e) => {
                const { installation, phaseRotation, ...rest } = data;
                const cfgData = {
                    installation: installation,
                    phaseRotation: connectorPhaseToConfig(phaseRotation),
                    ...rest
                };
                updateConfigM.mutate({
                    cpId: chargePoint.cpId,
                    config: cfgData
                });
            }}>Save</Button>
        </div>
    )
}