import { useParams } from 'react-router-dom';
import { BalancingChart } from './charts/BalancingChart';
import { BalancingChart2 } from './charts/BalancingChart2';
import { CPStatus } from './CPStatus';
import styles from './Dashboard.module.css';
import { CircuitReadings } from './schema/CircuitReadings';
import { CircuitSchema } from './schema/CircuitSchema';

type Params = {
    circuitId: string
}

export const Dashboard = ({ circuitId }: Params) => {
    // const params = useParams();
    // const circuitId = params.circuitId!;
    return (
        <div className={styles.mainGrid}>
            <div className={styles.infoRow}>
                <CPStatus circuitId={circuitId} />
                <div style={{flexGrow: 1}}>
                    <CircuitReadings circuitId={circuitId} />
                </div>
            </div>
            <div className={styles.balancingCell}>
                <BalancingChart2 circuitId={circuitId} />
                {/* <BalancingChart circuitId={circuitId} /> */}
            </div>
        </div>
    )
}