export const circuitInfoSchema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "cpms": {
            "type": "string"
        }
    }
}

export const circuitInfoUiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/name",
            "label": "Site's Name"
        },
        {
            "type": "Control",
            "scope": "#/properties/cpms",
            "label": "CPMS Url"
        }
    ]
}