import { Button, TextField } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { trpc } from "../../utils/trpc"
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { RouterInputs, RouterOutputs } from "../../utils/trpc";

type Params = {
    cpId: string
}

type SetPowerType = RouterInputs["cpDebug"]["setPower"];
type ScheduleType = RouterInputs["cpDebug"]["getCompositeSchedule"];
const compositeSchema = {
    "type": "object",
    "properties": {
        "compositeSchedule": {
            "type": "object",
            "properties": {
                "connectorId": {
                    "type": "number"
                },
                "duration": {
                    "type": "number",
                },
            }
        }
    }
}


export const SetCPPower = ({ cpId }: Params) => {
    // const powerRef = useRef();
    const [data, setData] = useState<SetPowerType>({ cpId: cpId, profile: { value: 0, connectorId: 0, type: "power" } });
    const [scheduleData, setScheduleData] = useState<ScheduleType>({ cpId: cpId, compositeSchedule: { connectorId: 0, duration: 600 } });
    const setPowerM = trpc.cpDebug.setPower.useMutation();
    const connectors = trpc.cpDebug.getChargingConnectors.useMutation();
    const getSchedule = trpc.cpDebug.getCompositeSchedule.useMutation();

    useEffect(() => {
        if (cpId != undefined) {
            connectors.mutate(cpId);
            setData({
                cpId: cpId,
                profile: data.profile
            });
            setScheduleData({
                cpId: cpId,
                compositeSchedule: scheduleData.compositeSchedule
            });
        }
    }, [cpId]);

    const schema = useMemo(() => {
        if (connectors.data != undefined && connectors.data.length > 0) {
            return {
                "type": "object",
                "properties": {
                    "profile": {
                        "type": "object",
                        "properties": {
                            "type": {
                                "type": "string",
                                "enum": ["power", "current"]
                            },
                            "value": {
                                "type": "number"
                            },
                            "connectorId": {
                                "type": "number",
                                "enum": connectors.data
                            },
                            "duration": {
                                "type": "number",
                            },
                            "stackLevel": {
                                "type": "number",
                            },
                            "numberPhases": {
                                "type": "number",
                            },
                            "profileId": {
                                "type": "number",
                            }
                        }
                    }
                }
            }
        } else {
            return undefined;
        }
    }, [connectors.data]);

    useEffect(() => {
        if (connectors.data != undefined) {
            data.profile.connectorId = connectors.data[0];
            setData(data);
        }
    }, [connectors.data]);

    return (<div style={{ width: "300px" }}>

        {schema != undefined ?
            <>
                <JsonForms
                    schema={schema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={({ data }) => {
                        setData(data);
                    }} />
                <Button onClick={() => {
                    setPowerM.mutate(data);
                }}>Set</Button>
            </>
            :
            <div>No Charging Connector</div>
        }
        <div>
            <JsonForms
                schema={compositeSchema}
                data={scheduleData}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ data }) => {
                    setScheduleData(data);
                }} />
            <Button onClick={() => {
                getSchedule.mutate(scheduleData);
            }}>Get Schedule</Button>
            {getSchedule.data && <pre>{JSON.stringify(getSchedule.data, undefined, 4)}</pre>}
        </div>
        {/* <TextField id="power" label="Power" variant="outlined" inputRef={powerRef} /> */}
    </div>)
}