import { Navigate, redirect, useNavigate, useRouteError } from 'react-router-dom';
import { LoginPage } from '../LoginPage';
import { QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { trpc } from '../utils/trpc';
import { buildQueryClient, buildTRCPClient, trpcDirect } from '../utils/appUtils';
import { ErrorBoundary } from '../utils/ErrorBoundary';
import { ThemeProvider } from '@mui/system';
import { energonTheme } from '../theme/muiTheme';
import { MainContainer } from './MainContainer';
import { Circuit } from './Circuit';
import { AddNewCircuit } from './circuitinfo/AddNewCircuit';
import { AddUser } from './users/AddUser';


export const Login = () => {
  const navigate = useNavigate();
  return <LoginPage onSuccess={() => navigate("/circuits")} />
}


export function MainApp() {

  const [queryClient] = useState(buildQueryClient);
  const [trpcClient] = useState(buildTRCPClient);


  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/">
        <Route path='/login' element={<Login />} />
        <Route index loader={async () => {
            const circuits = await trpcDirect.getCircuits.query();
            if (circuits.length > 0) {
              return redirect(`/circuits/${circuits[0].id}/dashboard`);
            }
            return {}
        }} errorElement={<ErrorBoundary errorPath='/login' />}/>

        <Route path="/circuits" element={<MainContainer />}
          errorElement={<ErrorBoundary errorPath='/login' />} 
          loader={async () => {
            const circuits = await trpcDirect.getCircuits.query();
            return circuits;

          }}>
          <Route index element={<Navigate to="default"  replace/>} />
          <Route path="default" loader={async () => {
            const circuits = await trpcDirect.getCircuits.query();
            if (circuits.length > 0) {
              return redirect(`/circuits/${circuits[0].id}/dashboard`);
            }
            return {}
          }}
          ></Route>
          <Route path='new' element={<AddNewCircuit />} />
          <Route path=':circuitId/dashboard' element={<Circuit />} />
        </Route>

        <Route path="/user" element={<MainContainer />}
          errorElement={<ErrorBoundary errorPath='/login' />}>
          <Route path='new' element={<AddUser />} />
        </Route>
      </Route>
    )
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={energonTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
