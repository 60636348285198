import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
import { ArrayElement } from "powerlinks-common";
import { Button } from "@mui/material";

type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

type Params = {
    chargePoint: CP
}

type ConfigKey = {
    key: string,
    value: string,
    readonly: boolean
}

export const GetConfiguration = ({ chargePoint }: Params) => {
    const getConfigQ= trpc.cpDebug.getOCPPConfig.useMutation();
    let result;
    if (getConfigQ.data?.configurationKey != undefined) {
        const arr = getConfigQ.data?.configurationKey as Array<ConfigKey>;
        result = arr.map((v) => {
        return (<>
            <div>{v.key}</div>
            <div>{v.value}</div>
            <div>{v.readonly ? "RO" : "R/W"}</div>
            </>
        )});
    }
    return (<div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button onClick={() => {
            getConfigQ.mutate(chargePoint.cpId);
        }}>Get Configuration</Button>
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            width: '1200px'
        }}>{result}</div>
    </div>)
}