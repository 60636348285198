import { defaultText } from '../utils/stringUtils';
import { trpc } from '../utils/trpc';
import styles from './CPStatus.module.css';

const SimpleGauge = (params: { text: string, heading: string, color: string }) => {
    return (<div className={styles.gauge}>
        <div>{params.heading}</div>
        <div style={{
                    borderColor: params.color
            }} className={styles.bubble}>{params.text}
    </div>
    </div>)
}



export const CPStatus = (params: {circuitId: string}) => {
    const cpStats = trpc.getCircuitCPStatus.useQuery(params.circuitId,{
        refetchInterval: 60000
    });
    
    return (<div className={styles.wrapper}>
        <div className={styles.GaugeContainer}>
            <div style={{color: 'black'}}>Total: {defaultText(cpStats.data?.total)}</div>
            <div style={{color: '#377eb8'}}>Charging: {defaultText(cpStats.data?.charging)}</div>
            <div style={{color: '#4daf4a'}}>Available: {defaultText(cpStats.data?.available)}</div>
            <div style={{color: '#ff7f00'}}>Unavailable: {defaultText(cpStats.data?.unavailable)}</div>
            {/* <SimpleGauge text={defaultText(cpStats.data?.total)} color='black' heading='Total' />
            <SimpleGauge text={defaultText(cpStats.data?.charging)} color='#377eb8' heading='Charging'/>
            <SimpleGauge text={defaultText(cpStats.data?.available)} color='#4daf4a' heading='Available'/>
            <SimpleGauge text={defaultText(cpStats.data?.unavailable)} color='#ff7f00' heading='Unavailable'/> */}
        </div>
    </div>)
}