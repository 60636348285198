import { trpc } from "../../utils/trpc"

type Params = {
    circuitId?: string
}

export const CircuitLogo = ({circuitId}: Params) => {
    const logoQ = trpc.manageCircuit.getCircuitLogo.useQuery(circuitId ? circuitId : "");
    return (
        <>
            {logoQ.data?.logo != undefined && <img src={logoQ.data.logo} style={{maxHeight: '100%', maxWidth:'100%',margin: 'auto'}}/>}
            {logoQ.data?.logo == undefined && <div style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid black"
            }}>Your Logo</div>}
        </>
    )
}