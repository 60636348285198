import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CircuitChargePoints } from "./CircuitChargePoints";
import { CircuitInfo } from "./circuitinfo/CircuitInfo";
import { CircuitDevices } from "./CircuitDevices";
import { Dashboard } from "./Dashboard";
import { Strategy } from "./Strategy";
import { trpc } from "../utils/trpc";
import { CircuitUsers } from "./CircuitUsers";

export const Circuit= () => {
    const params = useParams();
    const circuitId = params.circuitId!;
    const [value, setValue] = useState('1');
    const circuitPermission = trpc.manageCircuit.getCircuitPermission.useQuery(circuitId);
    const isAdmin = useMemo(() => {
      if (circuitPermission.data == undefined) {
          return false;
      } else {
          return circuitPermission.data == "admin";
      }
  }, [circuitPermission.data]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
    return (
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Dashboard" value="1" />
            <Tab label="Info" value="2" />
            <Tab label="Charge Points" value="3" />
            <Tab label="Devices & Readings" value="4" />
            <Tab label="Balancing Strategy" value="5" />
            {isAdmin && <Tab label="Users" value="6" />}
          </TabList>
        </Box>
        <TabPanel sx={{padding: '0px'}} value="1"><Dashboard circuitId={circuitId}/></TabPanel>
        <TabPanel value="2"><CircuitInfo circuitId={circuitId}/></TabPanel>
        <TabPanel value="3"><CircuitChargePoints circuitId={circuitId} /> </TabPanel>
        <TabPanel value="4"><CircuitDevices circuitId={circuitId}/></TabPanel>
        <TabPanel value="5"><Strategy circuitId={circuitId}/></TabPanel>
        {isAdmin && <TabPanel value="6"><CircuitUsers circuitId={circuitId}/></TabPanel>}
      </TabContext>
    );
}