import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { RouterOutputs, trpc } from "../../utils/trpc";
import { ReloadCircuit } from "../ReloadCircuit";
import { CircuitExtendedInfo } from "./CircuitExtendedInfo";
import { circuitInfoSchema, circuitInfoUiSchema } from "./circuitInfoSchema";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CircuitLogo } from "./CircuitLogo";

type Params = {
    circuitId: string
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


type CircuitInfoType = RouterOutputs["manageCircuit"]["getCircuitInfo"];
export const CircuitInfo = ({ circuitId }: Params) => {
    const [data, setData] = useState<CircuitInfoType | undefined>();
    const circuitInfo = trpc.manageCircuit.getCircuitInfo.useQuery(circuitId);
    const queryClient = useQueryClient();
    const changePhotoM = trpc.manageCircuit.updateCircuitLogo.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries();
        }
    });
    const setCircuitInfo = trpc.manageCircuit.saveCircuitInfo.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries();
        }
    });
    useEffect(() => {
        if (circuitInfo.data != undefined) {
            setData(circuitInfo.data);
        }
    }, [circuitInfo.data]);
    return (
        <div>
            <div style={{
                width: '1000px',
                display: 'flex',
                gap: '10px',
                justifyContent: "space-between",
                flexDirection: 'row',
            }}>
                <div style={{
                    width: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end'
                }}>
                    {data && <JsonForms
                        schema={circuitInfoSchema}
                        uischema={circuitInfoUiSchema}
                        data={data}
                        renderers={materialRenderers}
                        cells={materialCells}
                        readonly={data.permission == "read"}
                        onChange={({ data }) => {
                            setData(data);
                        }} />}
                    {data?.permission != "read" && <div>
                        <Button onClick={() => {
                            if (data != undefined) {
                                setCircuitInfo.mutate(data);
                            }
                        }}>Save</Button>
                        <ReloadCircuit circuitId={circuitId} />
                    </div>}
                </div>
                <div  style={{display: 'flex', flexDirection:'column', rowGap: '0.2rem'}}>
                    <div style={{ width: '200px', height: '75px'}}><CircuitLogo circuitId={circuitId} /></div>
                    {data?.permission != "read" && <Button component="label" variant="contained"
                        startIcon={<CloudUploadIcon />}>
                        Upload file
                        <VisuallyHiddenInput onChange={(e) => {
                            const files: FileList | null = e.target.files;
                            if (files != null && files.length > 0) {
                                const f = files[0];
                                var reader = new FileReader();
                                reader.onload = function () {
                                    if (reader.result != undefined) {
                                        const base64 = reader.result.toString().split(",")[1];
                                        changePhotoM.mutate({
                                            circuitId: circuitId,
                                            filename: f.name,
                                            dataBase64: base64
                                        });
                                    }
                                };
                                reader.readAsDataURL(f);
                            }
                        }} type="file" />
                    </Button>
                    }
                </div>
            </div>
            <CircuitExtendedInfo circuitId={circuitId} />
        </div>)
};