import { Button, TextField } from "@mui/material"
import { useRef } from "react"
import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
import { ArrayElement } from "powerlinks-common";

type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

type Params = {
    chargePoint: CP
}

export const RebootChargePoint = ({chargePoint} : Params) => {
    const rebootM = trpc.cpDebug.reboot.useMutation();
    return (
        <Button onClick={() => {
            rebootM.mutate(chargePoint.cpId);
        }}>Reboot</Button>
    )
};
