import { Box, Button, IconButton } from "@mui/material";
import { MaterialReactTable, MRT_ColumnDef, MRT_RowSelectionState } from "material-react-table";
import { ArrayElement } from "powerlinks-common";
import { useEffect, useMemo, useState } from "react";
import { RouterOutputs, trpc } from "../utils/trpc"
import type { ErrorObject } from 'ajv';
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

const schema = {
    "type": "object",
    "properties": {
        "email": {
            "type": "string"
        },
        "permission": {
            "type": "string",
            "enum": [
                "read",
                "write",
                "admin"
            ]

        }
    }
}

const uiSchema = {
    "type": "HorizontalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/email",
            "label": "Email"
        },
        {
            "type": "Control",
            "scope": "#/properties/permission",
            "label": "Permission"
        },
    ]
}

type Params = {
    circuitId: string
}

type User = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitUsers"]>;

export const CircuitUsers = ({ circuitId }: Params) => {
    const usersQ = trpc.manageCircuit.getCircuitUsers.useQuery(circuitId);
    const queryClient = useQueryClient();
    const addUserM = trpc.manageCircuit.addCircuitUser.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(getQueryKey(trpc.manageCircuit.getCircuitUsers));
        }
    });
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [user, setUser] = useState<User>();
    const [showAdd, setShowAdd] = useState(false);
    const [data, setData] = useState<Partial<User>>({});

    const removeUserM = trpc.manageCircuit.removeCircuitUser.useMutation({
        onSuccess: () => {
            const queryKey = getQueryKey(trpc.manageCircuit.getCircuitUsers);
            queryClient.invalidateQueries(queryKey);
        }
    });    
    const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([]);
    useEffect(() => {
        if (!addUserM.isLoading && addUserM.data != undefined) {
            if (addUserM.data.success) {
                setData({
                });
                setShowAdd(false);
                setAdditionalErrors([]);
            } else {
                const newError: ErrorObject = {
                    // AJV style path to the property in the schema
                    dataPath: '/email',
                    // message to display
                    message: addUserM.data.error,
                    schemaPath: '',
                    keyword: '',
                    params: {},
                };
                setAdditionalErrors([newError]);

            }
        }
    }, [addUserM.data]);
    const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
        {
            header: 'Email',
            accessorKey: 'email',
            size: 100
        },
        {
            header: "Permission",
            accessorKey: 'permission',
            size: 50
        },
    ], []);
    const gridHeight = useMemo(() => {
        if (usersQ.data != undefined) {
            return Math.min(100 + 45 * Math.max(usersQ.data.length, 2), 400);
        }
        return 400;
    }, [usersQ.data]);
    return <div style={{ width: 550, display: 'flex', flexDirection: 'column' }}>
        <div style={{ height: gridHeight }}>
            <MaterialReactTable
                columns={columns}
                data={usersQ.data ?? []}
                enableGlobalFilter={false} //turn off a feature
                enableRowActions
                positionActionsColumn="last"
                enablePagination={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: 'Remove', //change header text
                        size: 50, //make actions column wider
                    },
                }}
                getRowId={(originalRow) => originalRow.id}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: (e) => {
                        setRowSelection((_) => {
                            setUser(row.original);
                            return {
                                [row.id]: true,
                            }
                        })
                    },
                    selected: rowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                renderRowActions={({ row }) => {
                    return <Box>
                        <IconButton onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            removeUserM.mutate(row.original.id);
                            // removeCP.mutate({
                            //     circuitId: circuitId,
                            //     cpId: row.original.id
                            // });

                        }
                        }>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }}

            />
        </div>
        {showAdd ?
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <JsonForms
                    schema={schema}
                    uischema={uiSchema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    additionalErrors={additionalErrors}
                    onChange={({ data }) => {
                        setData(data);
                    }} />
                <div style={{ width: 500, display: 'flex', justifyContent: 'end' }}>
                    <Button onClick={() => {
                        setShowAdd(false);
                        setAdditionalErrors([]);
                    }}>Cancel</Button>
                    <Button onClick={async () => {
                        console.log(data);
                        addUserM.mutate({
                            circuitId: circuitId,
                            email: data.email!,
                            permission: data.permission! 
                        });
                    }}>Add</Button>
                </div>
            </div>
            :
            <Button sx={{ alignSelf: 'flex-end' }}
                onClick={() => setShowAdd(true)}>Add New User</Button>
        }

    </div>
}