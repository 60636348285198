import { Box, Button, IconButton } from "@mui/material";
import { MaterialReactTable, MRT_ColumnDef, MRT_RowSelectionState } from "material-react-table";
import { useMemo, useState } from "react";
import { trpc } from "../utils/trpc"
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { DateTime } from "luxon";
import { ReadingConfig } from "./ReadingConfig";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";

type Params = {
    deviceId?: string,
    readOnly: boolean
}

export const DeviceMeters = ({ deviceId, readOnly }: Params) => {
    const metersQ = trpc.manageCircuit.getCircuitDeviceMeters.useQuery(deviceId ?? "", {
        enabled: deviceId != undefined
    });
    const queryClient = useQueryClient();
    const deleteMeterM = trpc.manageCircuit.deleteCircuitDeviceMeter.useMutation({
        onSuccess: (r) => {
            const queryKey = getQueryKey(trpc.manageCircuit.getCircuitDeviceMeters);
            queryClient.invalidateQueries(queryKey);
        }
    });
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [isForm, setIsForm] = useState(false);

    const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
        {
            header: 'Name',
            accessorKey: 'name',
            size: 100
        },
        {
            header: "Last Update",
            id: "updatedAt",
            accessorFn: (row) => DateTime.fromJSDate(row.updatedAt).toISODate(),
            size: 100
        },
        {
            header: 'Driver',
            accessorKey: 'config.driver',
            size: 100
        },
        {
            header: 'Source',
            accessorKey: 'config.sourceType',
            size: 100
        },
        {
            header: 'Measurand',
            accessorKey: 'config.measurand',
            size: 100
        }
    ], []);
    return (<div>
        {deviceId != undefined ?
            isForm ?
                <div>
                    <ReadingConfig config={{ config: { address: "", dataType: "float", driver: "VICTRON", measurand: "Power.Active.Import", sourceType: "GRID" } }}
                        readOnly={readOnly}
                        deviceId={deviceId} onDone={(mc) => {
                            setIsForm(false);
                        }} />
                </div>

                :
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {!readOnly && <Button style={{ alignSelf: 'end' }} onClick={() => setIsForm(true)}>Add Meter Reading</Button>}
                    <MaterialReactTable
                        columns={columns}
                        data={metersQ.data ?? []}
                        enableGlobalFilter={false} //turn off a feature
                        state={{
                            rowSelection,
                            columnVisibility: {
                                'mrt-row-actions': !readOnly
                            }
                        }}
                        enableRowActions
                        positionActionsColumn="last"
                        enablePagination={false}
                        enableTopToolbar={false}
                        enableBottomToolbar={false}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                header: 'Remove', //change header text
                                size: 50, //make actions column wider
                            },
                        }}
                        getRowId={(originalRow) => originalRow.id}
                        renderDetailPanel={({ row }) => {
                            return (
                                <div>
                                    <ReadingConfig config={row.original} deviceId={deviceId}
                                        readOnly={readOnly}
                                        onDone={(mc) => {
                                            row.toggleExpanded();
                                        }} />
                                </div>
                            )
                        }}
                        renderRowActions={({ row }) => (
                            <Box>
                                <IconButton onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteMeterM.mutate({
                                        deviceId: deviceId,
                                        meterId: row.original.id
                                    });
                                }
                                }>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        )}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (e) => {
                                row.toggleExpanded();
                                setRowSelection((_) => {
                                    return {
                                        [row.id]: true,
                                    }
                                })
                            },
                            selected: rowSelection[row.id],
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                    />
                </div>

            : <div>Please Save the Device before Adding Meters</div>}
    </div>)
}