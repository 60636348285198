import { Button, TextField } from "@mui/material"
import { useRef } from "react"
import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
import { ArrayElement } from "powerlinks-common";

type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

type Params = {
    chargePoint: CP
}

export const TestCP = ({chargePoint} : Params) => {
    const testM = trpc.cpDebug.test.useMutation();
    return (
        <Button onClick={() => {
            testM.mutate(chargePoint.cpId);
        }}>Test</Button>
    )
};
