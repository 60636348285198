import { ArrayElement } from "powerlinks-common";
import { RouterOutputs } from "../../utils/trpc";
import { phaseToLabel } from "../../utils/energyUtils";
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { Tree, TreeMultipleSelectionKeys, TreeNodeTemplateOptions } from 'primereact/tree';
import { TreeNode } from "primereact/treenode";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./tree.css";

type SeriesType = ArrayElement<RouterOutputs["history"]["getBalancingHistory"]>;
type HistoryInfo = SeriesType["meta"];
type ChartStyleInfo = {
    color: string,
    label: string
}

export type StyledHistory = ChartStyleInfo & {
    id: number
}
type Params = {
    historyInfos: Array<HistoryInfo>,
    onChanged: (checked: Array<StyledHistory>) => void
};
const chartPalette = ['#e41a1c', '#e41a1c', '#e41a1c', '#e41a1c', '#e41a1c', '#999999', "#e60049", "#0bb4ff", "#50e991", "#e6d800", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0", "#b30000", "#7c1158"];

const chartsStyle: Record<string, ChartStyleInfo> = {
    "SOLAR": {
        color: "#4daf4a",
        label: "Solar"
    },
    "BATTERY": {
        color: "#984ea3",
        label: "Battery"
    },
    "GRID": {
        color: "#ff7f00",
        label: "Grid Import"

    },
    "BUILDING": {
        color: "#a65628",
        label: "Building"
    },
    "CP": {
        color: "#377eb8",
        label: "EV"
    },
    "PRICE": {
        color: "#00bfa0",
        label: "Price"
    }
}

const labelHI = (hi: HistoryInfo) => {
    if (hi.sourceType != undefined) {
        const phaseLabel = phaseToLabel(hi.phase);
        if (phaseLabel != 'ALL') {
            return phaseLabel;
        }
        if (hi.unit == "EUR") {
            return chartsStyle[hi.sourceType].label; 
        }
        if (hi.cpId != undefined) {
            const connector = hi.connectorId != undefined ? `/${hi.connectorId}` : "";
            return `${hi.cpId}${connector}/${phaseLabel}`;
        }
        return `${chartsStyle[hi.sourceType].label}/${phaseLabel}`;
    }
    return "";
}

const hi2sh = (hi: HistoryInfo, idx: number) => {
    if (hi.sourceType != undefined) {
        let label = `${chartsStyle[hi.sourceType].label}`;
        let strokeColor = chartsStyle[hi.sourceType].color;//chartPalette[idx];
        if (hi.cpId != undefined) {
            const connector = hi.connectorId != undefined ? `/${hi.connectorId}` : "";
            label = `${label} (${hi.cpId}${connector})`;
            strokeColor = chartPalette[idx];
        }
        label = `${label} / ${phaseToLabel(hi.phase)}`;
        label = labelHI(hi);
        return {
            id: hi.id,
            color: strokeColor,
            label: label
        }
    } else {
        return {
            id: hi.id,
            color: "",
            label: ""
        }
    }

}

type NodeHistoryInfo = {
    hi?: HistoryInfo,
    style?: StyledHistory,
    // checked: boolean
}

type HITreeNode = {
    hi?: NodeHistoryInfo,
    children?: Record<string, HITreeNode>
}

const valueWithDefault = (x: any, d: string): string => {
    if (x == undefined) return d;
    if (typeof x == 'string')
        return x;
    return '' + x;
}

const generateKey = (hi: HistoryInfo): Array<string> => {
    const ret: Array<string> = [valueWithDefault(hi.sourceType, 'ALL'),
    valueWithDefault(hi.cpId, 'ALL'), valueWithDefault(hi.connectorId, 'ALL'), phaseToLabel(hi.phase)];
    return ret;
}

const addHistory = (node: HITreeNode, hi: HistoryInfo, keys: Array<string>) => {
    if (node.children == undefined) {
        node.children = {};
    }
    const [nextKey, ...rest] = keys;
    let nextNode: HITreeNode;
    if (node.children[nextKey] == undefined) {
        nextNode = {};
        node.children[nextKey] = nextNode;
    } else {
        nextNode = node.children[nextKey];
    }
    if (rest.length > 0) {
        addHistory(nextNode, hi, rest);
    } else {
        nextNode.hi = {
            hi: hi,
            // checked: false
        };
    }
}

const compressTree = (node: HITreeNode) => {
    if (node.children != undefined) {
        const children = Object.entries(node.children);
        if (children.length == 1) {
            const child = children[0][1];
            if (node.hi == undefined) {
                node.hi = child.hi;
            }
            node.children = child.children;
            compressTree(node);
        } else {
            for (const [key, child] of children) {
                compressTree(child);
            }
        }
        if (node.hi == undefined && node.children != undefined) {
            if (node.children['ALL'] != undefined) {
                node.hi = node.children['ALL'].hi;
                delete node.children['ALL'];
            } 
        }
    }
}

const labelForMissing = (hi: HistoryInfo): string => {
    if (hi.cpId != undefined) {
        return `${hi.cpId} (missing)`;
    }
    return  `${chartsStyle[hi.sourceType!].label} (missing)`
}

const styleTree = (node: HITreeNode, startCount: number) => {
    let count = startCount;
    if (node.hi?.hi != undefined) {
        node.hi.style = hi2sh(node.hi.hi, count);
        count = count + 1;
    }
    if (node.children != undefined) {
        for (const [k, h] of Object.entries(node.children)) {
            styleTree(h, count);
        }
        if (node.hi == undefined) {
            const children = Object.entries(node.children);
            const child = children[0][1];
            if (child.hi?.style != undefined && child.hi?.hi != undefined) {
                node.hi = {
                    style: {
                        id: -1,
                        color: child.hi?.style?.color,
                        label: labelForMissing(child.hi.hi)
                    },
                }
            }
        }
    }
}

const buildTree = (histories: Array<HistoryInfo>) => {
    const ret: HITreeNode = {};
    for (const hi of histories) {
        const keys = generateKey(hi);
        addHistory(ret, hi, keys);
    }
    return ret;
}


const hiToPrimeTree = (node: HITreeNode, key: string) => {
    const ret: TreeNode = {
        key: node.hi?.hi != undefined ? node.hi.hi.id : "",
        selectable: node.hi?.hi != undefined,
        data: node.hi,
        label: node.hi?.style?.label,
    }
    if (node.children != undefined) {
        const children = Object.entries(node.children).map(([k, c]) => {
            const childKey = `${key}-${k}`;
            return hiToPrimeTree(c, childKey);
        });
        ret.children = children;
    }
    return ret;
}

const traverseTree = (nodes: TreeNode[], visitor: (node: NodeHistoryInfo) => void) => {
    for (const n of nodes) {
        if (n.data != undefined) {
            visitor(n.data as NodeHistoryInfo);
            if (n.children != undefined) {
                traverseTree(n.children, visitor);
            }
        }
    }
}

export const ChartLegend = ({ historyInfos, onChanged }: Params) => {
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<TreeMultipleSelectionKeys | null>(null);

    const nodeTemplate = (node: TreeNode, options: TreeNodeTemplateOptions) => {
        const key = node.key;
        const isChecked = selectedKeys != null && key != undefined && selectedKeys[key] == true;
        let label = <b>{node.label}</b>;
        const hi = node.data as NodeHistoryInfo;

        return <div>
            <Checkbox
                edge="start"
                checked={isChecked}
                disabled
                tabIndex={-1}
                disableRipple
                style={{
                    color: hi != undefined ? hi.style?.color : ""
                }}

            />
            <span className={options.className}>{label}</span></div>;
    }

    useEffect(() => {
        const root = buildTree(historyInfos);
        compressTree(root);
        styleTree(root, 0);
        const primeRoot = hiToPrimeTree(root, "");
        if (primeRoot.children != undefined || root.hi?.hi != undefined) {
            let nodes: TreeNode[] = [];
            if (root.hi?.hi != undefined) {
                nodes = [primeRoot];
            } else {
                nodes = primeRoot.children!;
            }
            setNodes(nodes);
            const checked = nodes.filter(tn => tn.data?.style != undefined).map(tn => {
                return (tn.data as NodeHistoryInfo).style!;
            });
            const newSelectedKeys: TreeMultipleSelectionKeys = {};
            for (const sh of checked) {
                newSelectedKeys[sh.id] = true;
            }
            onChanged(checked);
            setSelectedKeys(newSelectedKeys);
        }
    }, [historyInfos]);

    if (historyInfos == undefined) {
        return <div></div>
    }
    return (
        <div style={{
            minWidth: '250px'
        }}>
            <Tree value={nodes} selectionMode="multiple" selectionKeys={selectedKeys}
                metaKeySelection={false}
                // unstyled={true}
                nodeTemplate={nodeTemplate}
                propagateSelectionDown={false}
                propagateSelectionUp={false}
                onSelectionChange={(e) => {
                    setSelectedKeys(e.value as TreeMultipleSelectionKeys);
                    if (e.value != undefined) {
                        const selectedKeys = e.value as TreeMultipleSelectionKeys;
                        const newChecked: Array<StyledHistory> = [];
                        traverseTree(nodes, (h) => {
                            if (h.style != undefined && h.hi != undefined && selectedKeys[h.hi.id] == true) {
                                newChecked.push(h.style);
                            }
                        });
                        onChanged(newChecked);
                    }
                }} />
        </div>
        // <List sx={{ width: '100%', maxWidth: 200, bgcolor: 'background.paper' }} dense>
        //     {all.map((sh) => {
        //         if (sh != undefined) {
        //             const labelId = `checkbox-list-label-${sh.id}`;
        //             return (
        //                 <ListItem key={sh.id} dense disablePadding>
        //                     <ListItemButton role={undefined} dense onClick={() => {
        //                         const currentIdx = checked.findIndex((v) => v.id == sh.id);
        //                         const newChecked = [...checked];
        //                         if (currentIdx != -1) {
        //                             newChecked.splice(currentIdx, 1);
        //                         } else {
        //                             newChecked.push(sh);
        //                         }
        //                         setChecked(newChecked);
        //                         onChanged(newChecked);
        //                     }}>
        //                         <ListItemIcon>
        //                             <Checkbox
        //                                 edge="start"
        //                                 checked={checked.findIndex((v) => v.id == sh.id) !== -1}
        //                                 tabIndex={-1}
        //                                 disableRipple
        //                                 style={{
        //                                     color: sh.color
        //                                 }}
        //                                 inputProps={{ 'aria-labelledby': labelId }}
        //                             />
        //                         </ListItemIcon>
        //                         <ListItemText id={labelId} primary={`${sh.label}`} />
        //                     </ListItemButton>
        //                 </ListItem>
        //             )
        //         }
        //     })}
        // </List>
    )
}



// const RenderHistory = ({ hi }: { hi: NodeHistoryInfo }) => {
//     const labelId = `checkbox-list-label-${hi.hi?.id}`;
//     return (
//         <ListItem key={hi.hi?.id} dense disablePadding>
//             <ListItemButton role={undefined} dense>
//                 <ListItemIcon>
//                     <Checkbox
//                         edge="start"
//                         checked={hi.checked}
//                         tabIndex={-1}
//                         disableRipple
//                         style={{
//                             color: hi.style!.color
//                         }}
//                         inputProps={{ 'aria-labelledby': labelId }}
//                     />
//                 </ListItemIcon>
//                 <ListItemText id={labelId} primary={`${hi.style!.label}`} />
//             </ListItemButton>
//         </ListItem>
//     )
// }

// const RenderChildren = ({ children }: { children: Record<string, HITreeNode> }) => {
//     const cr = Object.entries(children).map(([k, c]) => {
//         return <RenderNode node={c} />
//     });
//     return <>
//         {cr}
//     </>
// }

// const RenderNode = ({ node }: { node: HITreeNode }) => {
//     const hasChildren = node.children != undefined;
//     return (
//         <>
//             {node.hi && <RenderHistory hi={node.hi} />}
//             {node.children != undefined &&
//                 <RenderChildren children={node.children} />
//             }
//         </>
//     )

// }
