import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { trpc } from "../../utils/trpc";
import styles from './BalancingChart.module.css';
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { getBalancingHistoryType } from "../../../../router/src/api/historyApi";
import { RouterOutputs } from "../../utils/trpc";
import { ArrayElement } from "powerlinks-common";
import ReactECharts from 'echarts-for-react';
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
import { ChartLegend, StyledHistory } from "./ChartLegend";
// https://github.com/apache/echarts/issues/12864
// https://github.com/apache/echarts/issues/12166
import zRenderEnv from 'zrender/lib/core/env';
zRenderEnv.touchEventsSupported = true;
zRenderEnv.pointerEventsSupported = false;

type TimePoint = {
    dt: Date;
    value?: number | undefined | null;
    historyInfoId?: number | undefined;
}

const nan2other = (a: Array<TimePoint>, nullValue: number | null = null) => {
    for (const point of a) {
        if (point.value != undefined && Number.isNaN(point.value as number)) {
            point.value = null;
        } else if (point.value == undefined) {
            point.value = nullValue;
        }
    }
    return a;
}

const aWeekAgo = DateTime.now().minus({ day: 7 }).toJSDate();

type SeriesType = ArrayElement<RouterOutputs["history"]["getBalancingHistory"]>;
type HistoryInfo = SeriesType["meta"];


const wattFormatter = (x: number | undefined) => {
    if (x != undefined) {
        const v = x as number;
        if (Math.abs(v) > 2000) {
            return (v / 1000).toFixed(2) + " kW";
        }
        return `${v.toFixed(0)} W`
    }
    return undefined;
}

const priceFormatter = (x: number | undefined) => {
    if (x != undefined) {
        const v = x as number;

        return `${v.toFixed(0)}€/Mw`
    }
    return undefined;
}



const rangePercent = (x?: number, defaultValue: number = 100, start?: number, end?: number) => {
    if (x == undefined) {
        return defaultValue;
    }
    if (start == undefined || end == undefined) {
        return defaultValue;
    }
    return (x - start) / (end - start) * 100;
}

export const BalancingChart2 = (params: { circuitId: string }) => {
    const [historyInfos, setHistoryInfos] = useState<Array<HistoryInfo>>([]);
    const [checked, setChecked] = useState<Array<StyledHistory>>([]);
    const [echart, setEchart] = useState<any>();
    const [zoom, setZoom] = useState<{ start: number, end: number }>({ start: 0, end: 100 });
    const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null);
    const [range, setRange] = useState<{ start?: number, end?: number }>({});
    const [queryParams, setQueryParams] = useState<getBalancingHistoryType>({
        circuitId: params.circuitId,
        scaleUnit: "min",
        scale: 15,
        startTime: aWeekAgo
    });
    const [options, setOptions] = useState<any>();
    const history = trpc.history.getBalancingHistory.useQuery(queryParams, {
        refetchInterval: 120000
    });
    useEffect(() => {
        setQueryParams({ ...queryParams, circuitId: params.circuitId });
        setHistoryInfos([]);
    }, [params.circuitId]);

    useEffect(() => {
        if (history.data != undefined) {
            if (historyInfos.length == 0) {
                const newHinfo = history.data.map((d) => d.meta);
                setHistoryInfos(newHinfo);
            }
        } else {
            setHistoryInfos([]);
        }
    }, [history.data]);


    useEffect(() => {
        if (history.data != undefined && history.data.length > 0 && checked != undefined) {
            const xAxis = history.data[0].data.map(c => c.dt.toISOString());
            const series = [];
            const dimensions = ['dt'];
            const seriesT: any[] = [];
            const source = [];
            for (const hi of history.data) {
                const sh = checked.find((v) => v.id == hi.meta.id);
                if (sh != undefined) {
                    if (hi.meta.unit == "W") {
                        const data = nan2other(hi.data, hi.meta.sourceType == "CP" ? 0 : null).map(c => c.value);
                        source.push(hi.data);
                        series.push({
                            data: data,
                            name: sh.label,
                            type: 'line'
                        });
                        const sname: string = `s${seriesT.length}`;
                        dimensions.push(sname);
                        seriesT.push({
                            name: sh.label,
                            type: 'line',
                            animation: false,
                            symbolSize: 1,
                            color: sh.color,
                            lineStyle: {
                                width: 3
                            },
                            smooth: 0.5,
                            encode: {
                                x: 'dt',
                                y: sname
                            }
                        });

                    } else {
                        const data = hi.data.map(v => ([+v.dt, v.value]));
                        seriesT.push({
                            name: sh.label,
                            type: 'line',
                            animation: false,
                            symbolSize: 1,
                            color: sh.color,
                            lineStyle: {
                                width: 1
                            },
                            step: 'middle',
                            yAxisIndex: 1,
                            data: data

                        })
                    }
                }
            }


            const seriesTdata = [];
            for (let i = 0; i < xAxis.length; i++) {
                const point: Array<any> = [xAxis[i]];
                for (let j = 0; j < series.length; j++) {
                    point.push(series[j].data[i]);
                }
                seriesTdata.push(point);
            }

            if (seriesTdata.length > 0) {
                const startTime = Date.parse(seriesTdata[0][0]);
                const endTime = Date.parse(seriesTdata[seriesTdata.length - 1][0]);
                setRange({
                    start: startTime,
                    end: endTime
                });
            }
            const allFormatter = (params: Array<any>, ticket: string): string | undefined => {
                let ret = "";
                let timeValue = "";
                for (const p of params) {
                    timeValue = p.axisValueLabel;
                    const serie = seriesT[p.seriesIndex];
                    const value = p.value[p.encode.y[0]];
                    let vf: string | undefined = "";
                    if (serie.name.indexOf("Price") != -1) {
                        vf = priceFormatter(value);
                    } else {
                        vf = wattFormatter(value);
                    }
                    if (vf != undefined) {
                        ret = ret + `${p.marker} ${serie.name} ${vf}<br/>`    
                    }
    
                }
                return `${timeValue} <br/>${ret}`;
            }

            const newOptions = {
                dataset: {
                    source: seriesTdata,
                    // source: source,
                    dimensions: dimensions
                },

                xAxis: [
                    {
                        type: 'time',
                        // data: xAxis,
                        offset: 0,
                        splitLine: {
                            show: true
                        },
                        // position: "top"
                        showMinLabel: true,
                        showMaxLabel: true,
                        axisLabel: {
                            formatter: (x: any, i: any, l: any) => {
                                const dt = new Date(x);
                                if (dt.getHours() == 0) {
                                    return "{primary|{MMM}}-{primary|{dd}}";
                                }
                                return "{HH}:{mm}"
                            }
                        },
                    }
                ],
                yAxis: [{
                    type: 'value',
                    name: "Power",
                    axisLabel: {
                        formatter: wattFormatter
                    }
                },
                {
                    // offset: -50,
                    type: 'value',
                    name: 'Price',
                    axisLabel: {
                        formatter: priceFormatter
                    }

                }],
                title: {
                    show: false,
                    text: "Hey"
                },
                grid: {
                    left: 60,
                    top: 60,
                    right: 70,
                    // bottom: 0
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        animation: false
                    },
                    // valueFormatter: wattFormatter,
                    formatter: allFormatter
                },
                toolbox: {
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        restore: {},
                    }
                },
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        realtime: true,
                        start: zoom.start,
                        end: zoom.end,
                        xAxisIndex: [0],
                        height: 30,
                        bottom: 20,
                        moveHandleSize: 15,
                        // brusgSelect: false
                    },
                    {
                        type: 'inside',
                        realtime: true,
                        start: zoom.start,
                        end: zoom.end,
                        xAxisIndex: [0]
                    }
                ],
                series: seriesT
            };
            setOptions(newOptions);
        }
    }, [history.data, checked]);

    return (
        <div className={styles.wrapper}>
            {(history.data == undefined || history.data.length == 0) && <div className={styles.noData}>No Data</div>}
            {(history.data != undefined && history.data.length > 0) &&
                <>
                    <div className={styles.chart}>
                        <ChartLegend historyInfos={historyInfos} onChanged={(v) => {
                            setChecked(v);
                        }} />
                        <div className={styles.chartArea}>
                            {options && <ReactECharts
                                onChartReady={(c) => {
                                    if (echart != undefined) {
                                        echart.off("datazoom");
                                    }
                                    c.on("datazoom", (a: any) => {
                                        if (a.batch != undefined && a.batch.length > 0) {
                                            const startValue = a.batch[0].startValue;
                                            const endValue = a.batch[0].endValue;
                                            const start = rangePercent(startValue, 0, range.start, range.end);
                                            const end = rangePercent(endValue, 100, range.start, range.end);
                                            setZoom({
                                                start: start,
                                                end: end
                                            });
                                        } else {
                                            setZoom({
                                                start: a.start,
                                                end: a.end
                                            });
                                        }
                                    });
                                    setEchart(c);
                                }}
                                option={options}
                                style={{ height: '70vh' }}
                                notMerge={true}
                            />}
                        </div>

                    </div>

                    <div className={styles.buttonBar}>
                        <Calendar value={dates} onChange={(e) => {
                            setDates(e.value);
                            if (e.value != null && e.value[0] != null && e.value[1] != null) {
                                setQueryParams({
                                    circuitId: params.circuitId,
                                    scaleUnit: "min",
                                    scale: 2,
                                    startTime: e.value[0],
                                    endTime: e.value[1]
                                });
                            }
                        }} selectionMode="range" readOnlyInput />
                        <Button onClick={() => {
                            const startDate = DateTime.now().minus({ day: 1 }).toJSDate();
                            setQueryParams({
                                circuitId: params.circuitId,
                                scaleUnit: "min",
                                scale: 2,
                                startTime: startDate
                            });
                            setZoom({
                                start: 0,
                                end: 100
                            });
                            setDates([startDate, new Date()]);
                        }}>1 Day</Button>
                        <Button onClick={() => {
                            const startDate = DateTime.now().minus({ day: 7 }).toJSDate();
                            setQueryParams({
                                circuitId: params.circuitId,
                                scaleUnit: "min",
                                scale: 10,
                                startTime: startDate
                            });
                            setZoom({
                                start: 0,
                                end: 100
                            });
                            setDates([startDate, new Date()]);
                        }}>1 Week</Button>
                        <Button onClick={() => {
                            const startDate = DateTime.now().minus({ month: 1 }).toJSDate();
                            setQueryParams({
                                circuitId: params.circuitId,
                                scaleUnit: "min",
                                scale: 30,
                                startTime: startDate
                            });
                            setZoom({
                                start: 0,
                                end: 100
                            });
                            setDates([startDate, new Date()]);
                        }}>1 Month</Button>
                    </div>
                </>

            }

        </div>)
}