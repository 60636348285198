import { PhaseLabelAll } from "powerlinks-common";

const phaseMap: Record<number, PhaseLabelAll> = {
    1: "L1",
    2: "L2",
    3: "L3"
}
export const phaseToLabel = (phase: number | undefined) : PhaseLabelAll => {
    if (phase == undefined) {
        return "ALL";
    } else {
        const ret = phaseMap[phase];
        if (ret != undefined) {
            return ret;
        }
        return "ALL";
    }
}