import { createTheme } from "@mui/material";
import type {} from '@mui/lab/themeAugmentation';

export const energonTheme = createTheme({
  typography: {
    fontFamily: [
      'Space Grotesk',
    ].join(','),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,0)'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '1px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingLeft: "5px",
          paddingRight: "5px"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '10px',
          height: "28px"
        }
      }
    }
  }
});