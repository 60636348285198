import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouteError } from "react-router-dom";
import { useState, useEffect } from 'react';

type Params = {
    errorPath: string
}

export const ErrorBoundary = ({ errorPath }: Params) => {
    let error: any = useRouteError();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    useEffect(() => {
        queryClient.invalidateQueries();
        navigate(errorPath);
    }, []);
    // Uncaught ReferenceError: path is not defined
    return (<div>Error </div>);
}