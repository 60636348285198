import { Box, Button, TextField } from "@mui/material"
import Typography from "@mui/material/Typography";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { trpc } from "./utils/trpc";
import styles from './LoginPage.module.css';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

export type Params = {
    onSuccess: () => void
}

export const LoginPage = ({ onSuccess }: Params) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const login = trpc.login.useMutation({
        onSuccess(data, variables, context) {
            if (data.status == "success") {
                localStorage.setItem("token", data.token);
                if (onSuccess != undefined) {
                    onSuccess();
                }
            } else {
                setError("");
            }
        },
        onError(error, variables, context) {
            setError("Login Error!");
        },
        useErrorBoundary: false
    });
    const loginWithGoogle = trpc.loginWithGoogle.useMutation({
        onSuccess(data, variables, context) {
            if (data.status == "success") {
                localStorage.setItem("token", data.token);
                if (onSuccess != undefined) {
                    onSuccess();
                }
            } else {
                setError("");
            }
        },
        onError(error, variables, context) {
            setError("Login Error!");
        },
        useErrorBoundary: false
    })


    return (
        <GoogleOAuthProvider clientId="441447808304-0ein0lmfbl6e9abou7m6pis9kip5ofcd.apps.googleusercontent.com">
            <Box className={styles.loginBg}>
                <p className={styles.loginEnergonTypography}>Energon System</p>
                <Box className={styles.loginBox}>
                    <Box className={styles.loginInner}>
                        <Typography sx={{ minWidth: '100px' }}>Log-In</Typography>
                        <Box className={styles.loginForm}>
                            <TextField
                                sx={{ width: '100%' }}
                                required
                                id="email"
                                label="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                required
                                sx={{ width: '100%', paddingTop: '10px' }}
                                id="password"
                                label="Password"
                                value={password}
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {(error != undefined) && <Typography color={"red"}>{error}</Typography>}

                        </Box>
                    </Box>
                    <Button onClick={() => {
                        login.mutate({
                            username: username,
                            password: password
                        });
                    }} >Login</Button>
                    <div style={{justifySelf: "center", display: "flex", flexDirection: "row"}}>
                        <Typography>Or Sign in with Google</Typography>
                        <GoogleLogin
                        onSuccess={credentialResponse => {
                            loginWithGoogle.mutate(credentialResponse.credential);
                        }}
                        onError={() => {
                            setError("Login Error!");
                        }}
                        
                    />
                    </div>
                    
                </Box>
                <Typography></Typography>
            </Box>
        </GoogleOAuthProvider>
    )
}