import { DateTime } from "luxon";
import { phaseToLabel } from "../../utils/energyUtils";
import { trpc } from "../../utils/trpc"
import styles from './CircuitExtendedInfo.module.css';

type Params = {
    circuitId: string
}

const toNow = (d: Date | undefined) => {
    if (d == undefined) {
        return "";
    }
    return (-DateTime.fromJSDate(d).diffNow('seconds').seconds).toFixed();
}


const formatValue = (v: number | boolean): string => {
if (typeof v == "boolean") {
    return "" + v;
} else {
    return (v as number).toFixed(2);
}
}

export const CircuitExtendedInfo = ({ circuitId }: Params) => {

    const cInfoQ = trpc.manageCircuit.getCircuitExtendedInfo.useQuery(circuitId);
    let summary, readings, connectors;
    if (cInfoQ.data != undefined) {
        summary = Object.entries(cInfoQ.data.summary.dataPoints).map(([a, b]) => {
            return <>
                <div>{a}</div>
                <div>{b["Power.Active.Import"]?.ALL?.timestamp.toLocaleTimeString()} ({toNow(b["Power.Active.Import"]?.ALL?.timestamp)}s)</div>
                <div>{b["Power.Active.Import"]?.ALL?.value.toFixed(2)}</div>
            </>
        });
        readings = cInfoQ.data.readings.map((r) => {
            return <>
                <div>{r.sourceType}</div>
                <div>{r.measurand} / {phaseToLabel(r.phase)}</div>
                <div>{r.chargePointId} ({r.connectorId})</div>
                <div>{r.timestamp.toLocaleTimeString()}({toNow(r.timestamp)}s)</div>
                <div>{formatValue(r.value)} ({r.unit})</div>
            </>
        });
        connectors = cInfoQ.data.connectors.map((c) => {
            return <>
                <div>{c.cpId} ({c.connectorId})</div>
            </>
        });
    }

    return (<div>
        <div className={styles.connectorWrapper}>
            <div className={styles.connectorHeader}>Charging Connectors</div>
            {connectors}
        </div>
        <div className={styles.summaryWrapper}>
            <div className={styles.summaryHeader}>Circuit Summary</div>
            <div>DataType</div>
            <div>TimeStamp</div>
            <div>Value</div>

            {summary}
        </div>

        <div className={styles.readingsWrapper}>
            <div className={styles.readingsHeader}>Circuit Readings</div>
            <div>DataType</div>
            <div>Measurand/Phase</div>
            <div>Chargepoint</div>
            <div>TimeStamp</div>
            <div>Value</div>

            {readings}
        </div>

    </div>)
}