import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab, IconButton } from "@mui/material";
import { ArrayElement } from "powerlinks-common";
import { useState } from "react";
import { RouterOutputs } from "../../utils/trpc";
import { GetConfiguration } from "./GetConfiguration";
import { RebootChargePoint } from "./RebootChargePoint";
import { SetCPPower } from "./SetCPPower";
import { TestCP } from "./TestCP";
import { UpdateConfig } from "./UpdateConfig";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PhaseConfig } from "./PhaseConfig";

type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

type Params = {
    chargePoint: CP
}

export const CPAdminPanel = ({ chargePoint }: Params) => {
    const [tabValue, setTabValue] = useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return <div>
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Power" value="1" />
                    <Tab label="OCPP Config" value="2" />
                    <Tab label="Phase Config" value="4" />
                    <Tab label="Tests" value="3" />
                </TabList>
            </Box>
            <TabPanel value="1" style={{ width: '1000px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>DB ID:</div>
                    <div style={{paddingRight: '20px', paddingLeft: '20px'}}>{chargePoint.id}</div>
                    <IconButton size="small" onClick={() => {
                        const txt = chargePoint.id;
                        if (txt != undefined) {
                            navigator.clipboard.writeText(txt);
                        }
                    }}>
                        <ContentCopyIcon />
                    </IconButton>
                </div>

                <SetCPPower cpId={chargePoint.cpId} />
            </TabPanel>
            <TabPanel value="2" style={{ width: '1000px' }}>
                <RebootChargePoint chargePoint={chargePoint} />
                <UpdateConfig chargePoint={chargePoint} />
                <GetConfiguration chargePoint={chargePoint} />
            </TabPanel>
            <TabPanel value="3" style={{ width: '1000px' }}>
                <TestCP chargePoint={chargePoint} />
            </TabPanel>
            <TabPanel value="4" style={{ width: '1000px' }}>
                <PhaseConfig chargePoint={chargePoint} />
            </TabPanel>
        </TabContext>

    </div>

}