import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Button } from "@mui/material";
import { useState } from "react";
import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
type UserAddType = RouterInputs["admin"]["addUser"];

const schema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "email": {
            "type": "string"
        },
        "password": {
            "type": "string"
        }
    }   
}


export const AddUser = () => {
    const [data, setData] = useState<UserAddType | undefined>();
    const addUserM = trpc.admin.addUser.useMutation();

    return (
        <div style={{
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end'
        }}>
            <div style={{alignSelf: 'start'}}>Add New User</div>
            <JsonForms
                schema={schema}
                data={data}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ data }) => {
                    setData(data);
                }} />
            <Button onClick={() => {
                if (data != undefined) {
                    addUserM.mutate(data);
                }
            }}>Add</Button>
            <pre>{JSON.stringify(addUserM.data)}</pre>

        </div>

    )
}