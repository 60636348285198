export const MeasurandValues = ["Energy.Active.Export.Register" , "Energy.Active.Import.Register" , "Energy.Reactive.Export.Register" , "Energy.Reactive.Import.Register" 
, "Energy.Active.Export.Interval" , "Energy.Active.Import.Interval" , "Energy.Reactive.Export.Interval" , "Energy.Reactive.Import.Interval" 
, "Power.Active.Export" , "Power.Active.Import" , "Power.Offered" , "Power.Reactive.Export" , "Power.Reactive.Import" , "Power.Factor" , "Current.Import" , "Current.Export" , "Current.Offered" , "Voltage" , "Frequency" , "Temperature" , "SoC" , "RPM"
, "CP.Charging"];

// export type Measurand = typeof MeasurandValues[number];


export type Measurand = "Energy.Active.Export.Register" | "Energy.Active.Import.Register" | "Energy.Reactive.Export.Register" | "Energy.Reactive.Import.Register" 
| "Energy.Active.Export.Interval" | "Energy.Active.Import.Interval" | "Energy.Reactive.Export.Interval" | "Energy.Reactive.Import.Interval" 
| "Power.Active.Export" | "Power.Active.Import" | "Power.Offered" | "Power.Reactive.Export" | "Power.Reactive.Import" | "Power.Factor" | "Current.Import" | "Current.Export" | "Current.Offered" | "Voltage" | "Frequency" | "Temperature" | "SoC" | "RPM"
| "CP.Charging";


export const DriverValues = ["MODBUS-TCP" , "MODBUS-SERIAL" , "VICTRON" , "REPLAY" , "FORMULA" , "FIXED", "HOME-WIZARD", "P1"];
export type Driver = "MODBUS-TCP" | "MODBUS-SERIAL" | "VICTRON" | "REPLAY" | "FORMULA" | "FIXED" | "HOME-WIZARD" | "P1";


export const DataTypeValues = ["float" , "numeric" , "boolean"];
export type DataType = "float" | "numeric" | "boolean";


export const SourceTypeValue = ["CP" , "SOLAR" , "GRID" , "BUILDING" , "BATTERY"];
export type SourceType = "CP" | "SOLAR" | "GRID" | "BUILDING" | "BATTERY"

export const UnitValues = ["Wh" , "kWh" , "varh" , "kvarh" , "W" , "kW" , "VA" , "kVA" , "var" , "kvar" , "A" , "V" , "K" , "Celcius" , "Fahrenheit" , "Percent"];
export type Unit = "Wh" | "kWh" | "varh" | "kvarh" | "W" | "kW" | "VA" | "kVA" | "var" | "kvar" | "A" | "V" | "K" | "Celcius" | "Fahrenheit" | "Percent";

export enum MeterDeviceType {
    CLOUD = "cloud",
    EDGE = "edge"
};

export type MeterReadingConfig = {
    id: string,
    measurand: Measurand,
    unit?: Unit,
    address: string,
    sourceType: SourceType,
    driver: Driver,
    dataType: DataType,
    chargePointId?: string,
    phase?: number,
    connectorId?: number
}

export type MeterReadingConfigDB = {
    measurand: Measurand,
    unit?: Unit,
    address: string,
    sourceType: SourceType,
    driver: Driver,
    dataType: DataType,
    chargePointId?: string,
    phase?: number,
    connectorId?: number
}

export type DeviceConfig = {
    deviceId: string;
    lastModified?: Date; 
    reading: Array<MeterReadingConfig>;
}


export type MeterReading = {
    id?: string,
    value: number | boolean,
    sourceType: SourceType,
    measurand?: Measurand,
    unit?: Unit,
    dataType: DataType,
    timestamp: Date,
    chargePointId?: string,
    phase?: number,
    connectorId?: number
}

export type MeterReadingBatch = {
  sourceId: string,
  readings: Record<string, MeterReading>
}


export type TimeValue = {
    timestamp: Date,
    value: number
};

export type TriPhaseValue = {
    ALL?: TimeValue;
    L1?: TimeValue;
    L2?: TimeValue;
    L3?: TimeValue;
}
export type PhaseLabel = "L1" | "L2" | "L3";
export type PhaseLabelAll = "L1" | "L2" | "L3" | "ALL";

export const phaseMap: Record<number, PhaseLabelAll> = {
    1: "L1",
    2: "L2",
    3: "L3"
}

export type OCCPPhases = "L1" | "L2" | "L3" | "N" | "L1-N" | "L2-N" | "L3-N" | "L1-L2" | "L2-L3" | "L3-L1";

export const phaseLabelMap: Record<OCCPPhases,number | undefined> = {
    L1: 1,
    L2: 2,
    L3: 3,
    "L1-N": 1,
    "L2-N": 2,
    "L3-N": 3,
    "L1-L2": undefined,
    "L2-L3": undefined,
    "L3-L1": undefined,
    N: undefined
}

export const phaseToLabel = (phase: number | undefined) : PhaseLabelAll => {
    if (phase == undefined) {
        return "ALL";
    } else {
        const ret = phaseMap[phase];
        if (ret != undefined) {
            return ret;
        }
        return "ALL";
    }
}


export const label2phase = (label:OCCPPhases | undefined) : number | undefined => {
    if (label == undefined) {
        return undefined;
    }
    const ret = phaseLabelMap[label];
    return ret;
}