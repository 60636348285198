import { Box, Button, IconButton } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import type { EdgeDeviceType } from "../../../router/src/api/manageCircuitApi";
import { trpc } from "../utils/trpc"
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { getQueryKey } from "@trpc/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { MRT_ColumnDef, MRT_RowSelectionState } from "material-react-table";
import { MaterialReactTable } from 'material-react-table';
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { DeviceMeters } from "./DeviceMeters";

type Params = {
    circuitId: string
}

type CircuitDeviceListParams = {
    devices?: EdgeDeviceType[],
    selected?: EdgeDeviceType,
    onSelect?: (device: EdgeDeviceType) => void,
    onRemove?: (device: EdgeDeviceType) => void,
    readOnly: boolean
}
export const CircuitDeviceList = ({ devices, selected, onSelect, onRemove, readOnly }: CircuitDeviceListParams) => {
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
        {
            header: 'Name',
            accessorKey: 'name',
            size: 100
        },
        {
            header: "Type",
            accessorKey: 'type',
            size: 50
        }
    ], []);
    const gridHeight = useMemo(() => {
        if (devices != undefined) {
            return Math.min(100 + 45 * Math.max(devices.length, 2), 400);
        }
        return 400;
    }, [devices]);

    useEffect(() => {
        if (selected != undefined && selected.id != undefined) {
            setRowSelection({
                [selected.id]: true
            });
        } else {
            setRowSelection({});
        }
    }, [selected]);

    return (
        <div style={{ height: gridHeight }}>
            <MaterialReactTable
                columns={columns}
                data={devices ?? []}
                enableGlobalFilter={false} //turn off a feature
                state={{
                    rowSelection: rowSelection,
                    columnVisibility: {
                        'mrt-row-actions': !readOnly
                    }
                }}
                enableRowActions
                positionActionsColumn="last"
                enablePagination={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: 'Remove', //change header text
                        size: 50, //make actions column wider
                    },
                }}
                getRowId={(originalRow) => originalRow.id}
                renderRowActions={({ row }) => (
                    <Box>
                        <IconButton onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (onRemove != undefined) {
                                onRemove(row.original);
                            }
                        }
                        }>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                )}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: (e) => {
                        setRowSelection((_) => {
                            if (onSelect != undefined) {
                                onSelect(row.original);
                            }
                            return {
                                [row.id]: true,
                            }
                        })
                    },
                    selected: rowSelection[row.id],
                    sx: {
                        cursor: 'pointer',
                    },
                })}
            />
            <div style={{ width: 500, display: 'flex', justifyContent: 'end' }}>
                {!readOnly && <Button onClick={() => {
                    if (onSelect != undefined) {
                        const device = {
                            name: 'New Device',
                            type: "edge"
                        };
                        onSelect(device);
                    }
                }}>Add</Button>}
            </div>
        </div>

    )
}

const schema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "type": {
            "type": "string",
            "enum": [
                "cloud",
                "edge"
            ]
        }
    }
}

const uiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/name",
            "label": "Device Name"
        },
        {
            "type": "Control",
            "scope": "#/properties/type",
            "label": "Device Type"
        },
    ]
}

export const CircuitDevices = ({ circuitId }: Params) => {
    const [device, setDevice] = useState<EdgeDeviceType>();
    const queryClient = useQueryClient();
    const edgeDevicesQ = trpc.manageCircuit.getCircuitEdgeDevices.useQuery(circuitId, {
        refetchInterval: 10000
    });
    const edgeDeviceUpsertM = trpc.manageCircuit.upsertCircuitDevice.useMutation({
        onSuccess: (r) => {
            const queryKey = getQueryKey(trpc.manageCircuit.getCircuitEdgeDevices);
            queryClient.invalidateQueries(queryKey);
            setDevice(r);
        }
    });
    const edgeDeviceRemoveM = trpc.manageCircuit.removeCircuitDevice.useMutation({
        onSuccess: () => {
            const queryKey = getQueryKey(trpc.manageCircuit.getCircuitEdgeDevices);
            queryClient.invalidateQueries(queryKey);
            setDevice(undefined);
        }
    });
    const [tabValue, setTabValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setDevice(undefined);
    }, [circuitId]);
    const circuitPermission = trpc.manageCircuit.getCircuitPermission.useQuery(circuitId);
    const isReadOnly = useMemo(() => {
        if (circuitPermission.data == undefined) {
            return true;
        } else {
            return circuitPermission.data == "read";
        }
    }, [circuitPermission.data]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '500px' }}>
                <CircuitDeviceList devices={edgeDevicesQ.data}
                    readOnly={isReadOnly}
                    onSelect={(d) => {
                        setDevice(d);
                        if (d.id == undefined) {
                            setTabValue('2');
                        }
                    }}
                    onRemove={(d) => {
                        edgeDeviceRemoveM.mutate(d);
                        setDevice(undefined);
                    }} selected={device} />

            </div>
            {device &&
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Readings" value="1" />
                            <Tab label="Info" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" style={{ width: '1000px' }}>
                        <DeviceMeters deviceId={device.id} readOnly={isReadOnly} />
                    </TabPanel>
                    <TabPanel value="2" style={{ maxWidth: '500px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {device?.id && <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ marginRight: '30px' }}>Device ID:</div>
                                <div>{device.id}</div>
                                <IconButton size="small" onClick={() => {
                                    const txt = device?.id;
                                    if (txt != undefined) {
                                        navigator.clipboard.writeText(txt);
                                    }
                                }}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </div>}
                            <JsonForms
                                schema={schema}
                                uischema={uiSchema}
                                data={device}
                                renderers={materialRenderers}
                                cells={materialCells}
                                readonly={isReadOnly}
                                onChange={({ data }) => {
                                    setDevice(data);
                                }} />
                            {!isReadOnly && <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button onClick={() => {
                                    setDevice(undefined);
                                }}>Cancel</Button>
                                <Button onClick={() => {
                                    edgeDeviceUpsertM.mutate({
                                        ...device,
                                        circuitId: circuitId
                                    });
                                }}>Save</Button>
                            </div>}
                        </div>


                    </TabPanel>
                </TabContext>

            }
        </div>
    )
}