import { Button } from "@mui/material"
import { trpc } from "../utils/trpc"

type Params = {
    circuitId: string
}

export const ReloadCircuit = ({ circuitId }: Params) => {
    const reloadM = trpc.manageCircuit.reloadCircuit.useMutation();
    return (<Button onClick={() => {
        reloadM.mutate(circuitId);
    }}>Reload</Button>);
}