import { style } from '@mui/system';
import { TriPhaseValue } from 'powerlinks-common';
import { defaultText, formatWithDefault } from '../../utils/stringUtils';
import { trpc } from '../../utils/trpc';
import styles from './CircuitSchema.module.css';
import { ArrayElement } from "powerlinks-common";
import { RouterOutputs } from "../../utils/trpc";

type CircuitReadingsType = RouterOutputs["getCircuitSummary"];


type Params = {
    v: TriPhaseValue | undefined
}

export const TriPhaseDisplay = ({ v }: Params) => {
    return (<div className={styles.BlockContent}>
        <div className={styles.allContent}>{formatWithDefault(v?.ALL?.value, '--')} W</div>
        {(v?.L1 || v?.L2 || v?.L1) &&
        <div className={styles.phaseWrapper}>
            {v?.L1 && <div className={styles.phaseContent}><div className={styles.phaseHeader}>L1</div><div className={styles.phase}>{formatWithDefault(v?.L1?.value, '--')}W</div></div>}
            {v?.L2 && <div className={styles.phaseContent}><div className={styles.phaseHeader}>L2</div><div className={styles.phase}>{formatWithDefault(v?.L2?.value, '--')}W</div></div>}
            {v?.L3 && <div className={styles.phaseContent}><div className={styles.phaseHeader}>L3</div><div className={styles.phase}>{formatWithDefault(v?.L3?.value, '--')}W</div></div>}
        </div>
        }
    </div>);
}

export const CircuitSchema = (params: { data?: CircuitReadingsType }) => {
    // const cpStats = trpc.getCircuitSummary.useQuery(params.circuitId, {
    //     refetchInterval: 30 * 1000
    // });

    return (<div className={styles.schemaGrid}>
        <div className={styles.topGridRow}>
            <div className={styles.BlockBox}>
                <div className={styles.BlockHeading}>
                    Grid
                </div>
                <TriPhaseDisplay v={params.data?.circuitInfo.dataPoints.GRID?.['Power.Active.Import']} />
            </div>
        </div>
        <div className={styles.topGridRow}>
            <div className={styles.BlockBox}>
                <div className={styles.BlockHeading}>
                    Local Production
                </div>
                <TriPhaseDisplay v={params.data?.circuitInfo.dataPoints.SOLAR?.['Power.Active.Import']} />
            </div>

        </div>
        <div className={styles.topGridRow}>
            <div className={styles.BlockBox}>
                <div className={styles.BlockHeading}>
                    Storage
                </div>
                <TriPhaseDisplay v={params.data?.circuitInfo.dataPoints.BATTERY?.['Power.Active.Import']} />
            </div>

        </div>
        <div className={styles.midGridRow}>
            <div className={styles.lineWrapper}>
                <div className={styles.topLine}></div>
                <div className={styles.rightLine}></div>
            </div>
        </div>
        <div className={styles.midGridRow}>
            <div className={styles.lineWrapper}>
                <div className={styles.fullHorizontalLine}></div>
                <div className={styles.topLine}></div>
                <div className={styles.bottomLine}></div>
            </div>

        </div>
        <div className={styles.midGridRow}>
            <div className={styles.lineWrapper}>
                <div className={styles.leftLine}></div>
                <div className={styles.topLine}></div>
                <div className={styles.bottomLine}></div>
            </div>

        </div>
        <div className={styles.bottomGridRow}>

        </div>
        <div className={styles.bottomGridRow}>
            <div className={styles.BlockBox}>
                <div className={styles.BlockHeading}>
                    Building
                </div>
                <TriPhaseDisplay v={params.data?.circuitInfo.dataPoints.BUILDING?.['Power.Active.Import']} />
            </div>

        </div>
        <div className={styles.bottomGridRow}>
            <div className={styles.BlockBox}>
                <div className={styles.BlockHeading}>
                    EV
                </div>
                <TriPhaseDisplay v={params.data?.circuitInfo.dataPoints.CP?.['Power.Active.Import']} />
            </div>

        </div>
    </div>)
}