import { circuitInfoSchema, circuitInfoUiSchema } from "./circuitInfoSchema";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { useState } from "react";
import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

type CircuitInfoType = RouterInputs["admin"]["addCircuit"];
export const AddNewCircuit = () => {
    const [data, setData] = useState<CircuitInfoType | undefined>();
    const queryClient = useQueryClient();
    const addCircuitM = trpc.admin.addCircuit.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries();
        }
    });
    return (
        <div style={{
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end'
        }}>
            <div style={{alignSelf: 'start'}}>Add New Circuit</div>
            <JsonForms
                schema={circuitInfoSchema}
                uischema={circuitInfoUiSchema}
                data={data}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ data }) => {
                    setData(data);
                }} />
            <Button onClick={() => {
                if (data != undefined) {
                    addCircuitM.mutate(data);
                }
            }}>Add</Button>
        </div>
    )
}