import { useEffect, useMemo, useState } from "react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react"
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { trpc } from "../utils/trpc";

type Params = {
    circuitId: string
}


const tryphaseDynamicSchema = {
    "type": "object",
    "properties": {
        connectorLimits: {
            "type": "object",
            "properties": {
                min: {
                    "type": "number"
                },
                max1Phase: {
                    "type": "number"
                },
                max3Phase: {
                    "type": "number"
                }
            }
        },
        defaultBudget: {
            "type": "object",
            "properties": {
                min: {
                    "type": "number"
                },
                max: {
                    "type": "number"
                },
                setPoint: {
                    "type": "number"
                }
            }
        },
        priceClasses: {
            type: "array",
            "items": {
                "type": "object",
                "properties": {
                    price: {
                        "type": "number"
                    },
                    budget: {
                        "type": "object",
                        "properties": {
                            min: {
                                "type": "number"
                            },
                            max: {
                                "type": "number"
                            },
                            setPoint: {
                                "type": "number"
                            }
                        }
                    },
                }
            },
        },
        startBudget: {
            "type": "number"
        },
        chargeProfileThreshold: {
            "type": "number"
        },
    }
};


const tryphaseDynamicUISchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Label",
            "text": "Powet Limits per Connector"
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/connectorLimits/properties/min",
                    "label": "Min Power (W)"
                },
            ]
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/connectorLimits/properties/max1Phase",
                    "label": "Max Power 1-phase (W)"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/connectorLimits/properties/max3Phase",
                    "label": "Max Power 3-phase (W)"
                },
            ]
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/startBudget",
                    "label": "Start Budget (W)"
                },
            ]
        },
        {
            "type": "Label",
            "text": "Grid Operating Margins"
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/defaultBudget/properties/min",
                    "label": "Min Grid Threshold (W)"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/defaultBudget/properties/max",
                    "label": "Max Grid Threshold (W)"
                },
            ]
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/defaultBudget/properties/setPoint",
                    "label": "Grid SetPoint (W)"
                },
            ]
        },
        {
            "type": "VerticalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/priceClasses",
                    options: {
                        elementLabelProp: 'price',
                        addButtonLabel: 'Add Price Budget',
                    }
                }
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/chargeProfileThreshold",
            "label": "Charge Profile Change Theshold (%)"
        },
    ]
};

const strategies: Record<string, any> = {
    "triphase-dynamic": {
        label: "Dynamic 3-phase",
        schema: tryphaseDynamicSchema,
        uiSchema: tryphaseDynamicUISchema
    },
    "triphase-lp": {
        label: "Dynamic 3-phase (LP)",
        schema: tryphaseDynamicSchema,
        uiSchema: tryphaseDynamicUISchema
    },
    "demo-solar": {
        label: "Demo Solar",
        schema: {
            "type": "object",
            "properties": {
                connectorLimits: {
                    "type": "object",
                    "properties": {
                        min: {
                            "type": "number"
                        },
                        max: {
                            "type": "number"
                        }
                    }
                },
                gridImportThreshold: {
                    "type": "object",
                    "properties": {
                        min: {
                            "type": "number"
                        },
                        max: {
                            "type": "number"
                        }
                    }
                },
                chargeProfileThreshold: {
                    "type": "number"
                },
            }
        },
        uiSchema: {
            "type": "VerticalLayout",
            "elements": [
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/connectorLimits/properties/min",
                            "label": "Min Power per Connector (W)"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/connectorLimits/properties/max",
                            "label": "Max Power per Connector (W)"
                        },
                    ]
                },
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/gridImportThreshold/properties/min",
                            "label": "Min Grid Import Threshold (W)"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/gridImportThreshold/properties/max",
                            "label": "Max Grid Import Threshold (W)"
                        },
                    ]
                },
                {
                    "type": "Control",
                    "scope": "#/properties/chargeProfileThreshold",
                    "label": "Charge Profile Change Theshold (%)"
                },
            ]

        }
    },
    "FixedBudget": {
        label: "Fixed Budget",
        schema: {
            "type": "object",
            "properties": {
                connectorLimits: {
                    "type": "object",
                    "properties": {
                        min: {
                            "type": "number"
                        },
                        max: {
                            "type": "number"
                        }
                    }
                },
                startBudget: {
                    "type": "number"
                },
                maxBudget: {
                    "type": "number"
                },
                chargeProfileThreshold: {
                    "type": "number"
                },
            }
        },
        uiSchema: {
            "type": "VerticalLayout",
            "elements": [
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/maxBudget",
                            "label": "Max Charging Budget (W)"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/startBudget",
                            "label": "Start Budget (W)"
                        },
                    ]
                },
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/connectorLimits/properties/min",
                            "label": "Min Power per Connector (W)"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/connectorLimits/properties/max",
                            "label": "Max Power per Connector (W)"
                        },
                    ]
                },
                {
                    "type": "Control",
                    "scope": "#/properties/chargeProfileThreshold",
                    "label": "Charge Profile Change Theshold (%)"
                },
            ]

        }
    },
    "dynamic-budget": {
        label: "Dynamic Budget",
        schema: {
            "type": "object",
            "properties": {
                connectorLimits: {
                    "type": "object",
                    "properties": {
                        min: {
                            "type": "number"
                        },
                        max: {
                            "type": "number"
                        }
                    }
                },
                defaultBudget: {
                    "type": "object",
                    "properties": {
                        min: {
                            "type": "number"
                        },
                        max: {
                            "type": "number"
                        },
                        setPoint: {
                            "type": "number"
                        }
                    }
                },
                priceClasses: {
                    type: "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            price: {
                                "type": "number"
                            },
                            budget: {
                                "type": "object",
                                "properties": {
                                    min: {
                                        "type": "number"
                                    },
                                    max: {
                                        "type": "number"
                                    },
                                    setPoint: {
                                        "type": "number"
                                    }
                                }
                            },
                        }
                    },
                },
                startBudget: {
                    "type": "number"
                },
                chargeProfileThreshold: {
                    "type": "number"
                },
            }
        },
        uiSchema: {
            "type": "VerticalLayout",
            "elements": [
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/connectorLimits/properties/min",
                            "label": "Min Power per Connector (W)"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/connectorLimits/properties/max",
                            "label": "Max Power per Connector (W)"
                        },
                    ]
                },
                {
                    "type": "Label",
                    "text": "Grid Operating Margins"
                },
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/defaultBudget/properties/min",
                            "label": "Min Grid Threshold (W)"
                        },
                        {
                            "type": "Control",
                            "scope": "#/properties/defaultBudget/properties/max",
                            "label": "Max Grid Threshold (W)"
                        },
                    ]
                },
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/startBudget",
                            "label": "Start Budget (W)"
                        },
                    ]
                },
                {
                    "type": "HorizontalLayout",
                    elements: [
                        {
                            "type": "Control",
                            "scope": "#/properties/defaultBudget/properties/setPoint",
                            "label": "Grid SetPoint (W)"
                        },
                    ]
                },
                {
                    "type": "VerticalLayout",
                    "elements": [
                        {
                            "type": "Control",
                            "scope": "#/properties/priceClasses",
                            options: {
                                elementLabelProp: 'price',
                                addButtonLabel: 'Add Price Budget',
                            }
                        }
                    ]
                },
                {
                    "type": "Control",
                    "scope": "#/properties/chargeProfileThreshold",
                    "label": "Charge Profile Change Theshold (%)"
                },
            ]

        }
    },
    "step": {
        label: "Step",
        schema: {
            "type": "object",
            "properties": {
                "steps": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            time: {
                                "type": "number"
                            },
                            value: {
                                "type": "number"
                            },
                        }
                    }
                }
            }
        }
    }
}

export const Strategy = ({ circuitId }: Params) => {
    const [data, setData] = useState();
    const [selectedStrategy, setStrategyName] = useState("");
    const strategyQ = trpc.manageCircuit.getCircuitStrategy.useQuery(circuitId);
    const strategyM = trpc.manageCircuit.setCircuitStrategy.useMutation();
    useEffect(() => {
        if (strategyQ.data != undefined) {
            setStrategyName(strategyQ.data.name);
            setData(strategyQ.data.options);
        } else {
            setStrategyName("");
        }
    }, [strategyQ.data]);
    const circuitPermission = trpc.manageCircuit.getCircuitPermission.useQuery(circuitId);
    const isReadOnly = useMemo(() => {
        if (circuitPermission.data == undefined) {
            return true;
        } else {
            return circuitPermission.data == "read";
        }
    }, [circuitPermission.data]);
    const availableStrategies = useMemo(() => {
        const ret = [
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
        ];
        for (let s in strategies) {
            ret.push(<MenuItem value={s}>{strategies[s].label}</MenuItem>);
        }

        return ret;
    }, []);
    return (<div>
        <div style={{
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end'
        }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 220, alignSelf: 'start' }}>
                <InputLabel id="demo-simple-select-standard-label">Balancing Strategy</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedStrategy}
                    readOnly={isReadOnly}
                    onChange={(e: SelectChangeEvent) => {
                        setData(undefined);
                        setStrategyName(e.target.value)
                    }}
                    label="Balancing Strategy"
                >
                    {availableStrategies}
                </Select>
            </FormControl>
            {selectedStrategy != "" &&
                <JsonForms
                    schema={strategies[selectedStrategy].schema}
                    uischema={strategies[selectedStrategy].uiSchema}
                    data={data}
                    readonly={isReadOnly}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={(e) => {
                        setData(e.data);
                    }} />
            }
            {!isReadOnly && <Button onClick={(e) => {
                console.log(selectedStrategy, data);
                strategyM.mutate({
                    name: selectedStrategy,
                    circuitId: circuitId,
                    context: data
                })
            }}>Save</Button>}
        </div>
    </div>);
}
