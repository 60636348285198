import { Drawer, Link, List, ListItem, ListItemButton } from "@mui/material"
import { NavLink as NavLinkBase, Outlet, useLoaderData, useNavigate, useParams } from "react-router-dom"
import styles from './MainContainer.module.css';
import energon_logo from '../assets/energon_logo.svg';
import React, { useEffect, useState } from "react";
import { trpc } from "../utils/trpc";
import { useQueryClient } from "@tanstack/react-query";
import { CircuitLogo } from "./circuitinfo/CircuitLogo";
import Background from '../assets/background.png';

const NavLink = React.forwardRef<any, any>((props, ref) => (
    <NavLinkBase
        ref={ref}
        {...props}
        className={props.activeClassName}
    />
));

export const MainContainer = () => {
    const navigate = useNavigate();
    const circuitsDefault = useLoaderData() as {
        name: string;
        id: string;
    }[];
    const [circuits, setCircuits] = useState(circuitsDefault);
    const params = useParams();
    const circuitId = params.circuitId;
    console.log(circuitId);
    const queryClient = useQueryClient();
    const circuitsQ = trpc.getCircuits.useQuery();
    useEffect(() => {
        if (circuitsQ.data != undefined) {
            setCircuits(circuitsQ.data);
        }
    }, [circuitsQ.data]);
    const profile = trpc.userProfile.getUserProfile.useQuery();
    const circuitsR = circuits.map((c) => <ListItem to={`/circuits/${c.id}/dashboard`} component={NavLink}
        activeClassName={({ isActive, isPending }: any) => isActive ? styles.navbarActive1D : styles.navbarInactive1D} >{c.name}</ListItem>
    )
    return (
        <div style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'round',
            height: '100vh'
        }}>
            <Drawer variant="permanent" style={{
                backgroundColor: 'rgba(255,255,255,0)'
            }}
                anchor="left">
                <div className={styles.wrapper}>
                    <List>
                        <div className={styles.your_logo}><CircuitLogo circuitId={circuitId} /></div>
                        {/* <ListItem disablePadding>
                            <ListItemButton >Locations</ListItemButton>
                        </ListItem> */}
                        <ListItem key="circuits" disablePadding>
                            <ListItem>
                                Locations:
                            </ListItem>
                        </ListItem>
                        <List sx={{ display: 'flex', flexDirection: 'column' }}>
                            {circuitsR}
                            {profile.data?.role == "admin" && <ListItem to={`/circuits/new`} component={NavLink}
                                activeClassName={({ isActive, isPending }: any) => isActive ? styles.navbarActive1D : styles.navbarInactive1D} >Add New</ListItem>}
                        </List>
                        {profile.data?.role == "admin" &&
                            <ListItem to={`/user/new`} component={NavLink}
                                activeClassName={({ isActive, isPending }: any) => isActive ? styles.navbarActive : styles.navbarInactive} >New User</ListItem>
                        }
                        <ListItem disablePadding>
                            <ListItemButton >Make Report</ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>Profile & Settings</ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                localStorage.removeItem("token");
                                queryClient.invalidateQueries();
                                navigate("/");
                            }}>Logout</ListItemButton>
                        </ListItem>
                    </List>
                    <img src={energon_logo} width='200px' className={styles.logo} />
                </div>
            </Drawer>
            <div className={styles.mainOutlet}>
                <Outlet />
            </div>
        </div>
    )
}