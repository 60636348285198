import { ArrayElement } from "powerlinks-common";
import { trpc } from '../../utils/trpc';
import { RouterOutputs } from "../../utils/trpc";
import { CircuitSchema, TriPhaseDisplay } from "./CircuitSchema";
import Grid from '@mui/material/Unstable_Grid2';
import { Box } from "@mui/material";
import styles from './CircuitSchema.module.css';

type CircuitReadingsType = RouterOutputs["getCircuitSummary"];

export const CircuitReadings = (params: { circuitId: string }) => {
    const cpStats = trpc.getCircuitSummary.useQuery(params.circuitId, {
        refetchInterval: 30 * 1000
    });

    return (
        <Grid container columns={5} spacing={0.1}>
            <Grid xs={1}>
                <div className={styles.GridHeading}>Grid</div>
            </Grid>
            <Grid xs={1}>
                <div className={styles.SolarHeading}>Solar</div>
            </Grid>
            <Grid xs={1}>
                <div className={styles.BaterryHeading}>Storage</div>
            </Grid>
            <Grid xs={1}>
                <div className={styles.BuildingHeading}>Building</div>
            </Grid>
            <Grid xs={1}>
                <div className={styles.EVHeading}>EV</div>
            </Grid>
            <Grid xs={1}>
                <TriPhaseDisplay v={cpStats.data?.circuitInfo.dataPoints.GRID?.['Power.Active.Import']} />
            </Grid>
            <Grid xs={1}>
                <TriPhaseDisplay v={cpStats.data?.circuitInfo.dataPoints.SOLAR?.['Power.Active.Import']} />
            </Grid>
            <Grid xs={1}>
                <TriPhaseDisplay v={cpStats.data?.circuitInfo.dataPoints.BATTERY?.['Power.Active.Import']} />
            </Grid>
            <Grid xs={1}>
                <TriPhaseDisplay v={cpStats.data?.circuitInfo.dataPoints.BUILDING?.['Power.Active.Import']} />
            </Grid>
            <Grid xs={1}>
                <TriPhaseDisplay v={cpStats.data?.circuitInfo.dataPoints.CP?.['Power.Active.Import']} />
            </Grid>

        </Grid>
    )
    // <CircuitSchema data={cpStats.data} />
}