import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";

import { useEffect, useMemo, useRef, useState } from "react";
import { RouterOutputs, trpc } from "../utils/trpc";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import type { ErrorObject } from 'ajv';
import { MaterialReactTable, MRT_ColumnDef, MRT_RowSelectionState } from "material-react-table";
import {
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { ArrayElement } from "powerlinks-common";
import { SetCPPower } from './cpdebug/SetCPPower';
import { CPAdminPanel } from './cpdebug/CPAdminPanel';

const schema = {
    "type": "object",
    "properties": {
        "cpId": {
            "type": "string"
        },
    }
}

const uiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/cpId",
            "label": "Charge Point ID"
        },
    ]
}
type NewCPFormType = {
    cpId: string;
}
type Params = {
    circuitId: string
}

type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

export const CircuitChargePoints = ({ circuitId }: Params) => {
    const [data, setData] = useState<NewCPFormType>({ cpId: "" });
    const [showAdd, setShowAdd] = useState(false);
    const [chargePoint, setChargePoint] = useState<CP>();
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const queryClient = useQueryClient();
    const [additionalErrors, setAdditionalErrors] = useState<ErrorObject[]>([]);
    const circuitPermission = trpc.manageCircuit.getCircuitPermission.useQuery(circuitId);
    const isReadOnly = useMemo(() => {
        if (circuitPermission.data == undefined) {
            return true;
        } else {
            return circuitPermission.data == "read";
        }
    }, [circuitPermission.data]);
    const isAdmin = useMemo(() => {
        if (circuitPermission.data == undefined) {
            return false;
        } else {
            return circuitPermission.data == "admin";
        }
    }, [circuitPermission.data]);



    const chargePointsQuery = trpc.manageCircuit.getCircuitChargePoints.useQuery(circuitId, {
        refetchInterval: 10000
    });

    const removeCP = trpc.manageCircuit.removeCPFromCircuit.useMutation({
        onSuccess: () => {
            const queryKey = getQueryKey(trpc.manageCircuit.getCircuitChargePoints);
            queryClient.invalidateQueries(queryKey);
        }
    });

    const addCP = trpc.manageCircuit.addCPToCircuit.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries(getQueryKey(trpc.manageCircuit.getCircuitChargePoints));
        }
    });

    useEffect(() => {
        if (!addCP.isLoading && addCP.data != undefined) {
            if (addCP.data) {
                setData({
                    cpId: ""
                });
                setShowAdd(false);
                setAdditionalErrors([]);
            } else {
                const newError: ErrorObject = {
                    // AJV style path to the property in the schema
                    dataPath: '/cpId',
                    // message to display
                    message: 'ChargePoint not found or already in use!',
                    schemaPath: '',
                    keyword: '',
                    params: {},
                };
                setAdditionalErrors([newError]);
            }
        }
    }, [addCP.data]);

    const columns = useMemo<MRT_ColumnDef<any>[]>(() => [
        {
            header: 'ChargePoint ID',
            accessorKey: 'cpId',
            size: 100
        },
        {
            header: "Charging",
            id: 'transactionId',
            accessorFn: (row) => row.transactionId != undefined ? 'On' : 'Off',
            size: 50
        },
        {
            header: "Connected",
            id: 'isConnected',
            accessorFn: (row) => row.isConnected ? 'On' : 'Off',
            size: 50
        }
    ], []);

    const gridHeight = useMemo(() => {
        if (chargePointsQuery.data != undefined) {
            return Math.min(100 + 45 * Math.max(chargePointsQuery.data.length, 2), 400);
        }
        return 400;
    }, [chargePointsQuery.data]);
    return (
        <div style={{ width: 550, display: 'flex', flexDirection: 'column' }}>
            <div style={{ height: gridHeight }}>
                <MaterialReactTable
                    columns={columns}
                    state={
                        {
                            columnVisibility: {
                                'mrt-row-actions': !isReadOnly
                            }
                        }
                    }
                    data={chargePointsQuery.data ?? []}
                    enableGlobalFilter={false} //turn off a feature
                    enableRowActions
                    positionActionsColumn="last"
                    enablePagination={false}
                    enableTopToolbar={false}
                    enableBottomToolbar={false}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Remove', //change header text
                            size: 50, //make actions column wider
                        },
                    }}
                    getRowId={(originalRow) => originalRow.id}
                    muiTableBodyRowProps={({ row }) => ({
                        onClick: (e) => {
                            setRowSelection((_) => {
                                setChargePoint(row.original);
                                return {
                                    [row.id]: true,
                                }
                            })
                        },
                        selected: rowSelection[row.id],
                        sx: {
                            cursor: 'pointer',
                        },
                    })}
                    renderRowActions={({ row }) => {
                        return <Box>
                            <IconButton onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                removeCP.mutate({
                                    circuitId: circuitId,
                                    cpId: row.original.id
                                });

                            }
                            }>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    }}

                />
            </div>
            {showAdd ?
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <JsonForms
                        schema={schema}
                        uischema={uiSchema}
                        data={data}
                        renderers={materialRenderers}
                        cells={materialCells}
                        additionalErrors={additionalErrors}
                        onChange={({ data }) => {
                            setData(data);
                        }} />
                    <div style={{ width: 500, display: 'flex', justifyContent: 'end' }}>
                        <Button onClick={() => {
                            setShowAdd(false);
                            setAdditionalErrors([]);
                        }}>Cancel</Button>
                        <Button onClick={async () => {
                            console.log(data);
                            const result = await addCP.mutate({
                                circuitId: circuitId,
                                cpId: data.cpId
                            });
                            console.log("Added REsult", result);
                        }}>Add</Button>
                    </div>
                </div>
                :
                <>
                    {!isReadOnly && <Button sx={{ alignSelf: 'flex-end' }}
                        onClick={() => setShowAdd(true)}>Add New ChargePoint</Button>}
                </>
            }
            {(chargePoint && isAdmin) && <CPAdminPanel chargePoint={chargePoint}/>}

        </div>
    )
};