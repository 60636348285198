import { QueryCache, QueryClient, useQueryClient } from "@tanstack/react-query";
import { createTRPCProxyClient, httpLink, TRPCClientError } from "@trpc/client";
import { useNavigate, useRouteError } from "react-router-dom";
import { AppRouter } from "../../../router/src/api/mainApi";
import { trpc } from "./trpc";
import superjson from 'superjson';

const APP_URL = import.meta.env.VITE_APP_URL != undefined ? import.meta.env.VITE_APP_URL : "http://localhost:2022"
console.log("APP_URL", APP_URL);

export const buildQueryClient = () => new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            retry: false
        },
        mutations: {
            retry: false,
            useErrorBoundary: true
        }
    },
    queryCache: new QueryCache({
        onError: (error) => {
            const trcpError = error as TRPCClientError<any>;
            if (trcpError.data.httpStatus == 401) {
                console.log("Auth ERROR");
            } else {
                console.log(error);
            }
        }
    }),
});


const authHTTPLink = httpLink({
    url: `${APP_URL}/trpc`,
    headers() {
        const token = localStorage.getItem("token");
        if (token != undefined) {
            return {
                Authorization: token,
                "no-cache": "" + Math.random()
            };
        } else {
            return {};
        }
    },
});

export const buildTRCPClient = () =>
    trpc.createClient({
        transformer: superjson,
        links: [
            authHTTPLink
        ],
    });


export const trpcDirect = createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [authHTTPLink]
});