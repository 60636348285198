import { JsonForms } from "@jsonforms/react"
import { ReadingConfigType } from "../../../router/src/api/manageCircuitApi"
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { Button } from "@mui/material";
import { DataTypeValues, DriverValues, MeasurandValues, SourceTypeValue, UnitValues } from 'powerlinks-common/src/edge/meterConfTypes';
import { useEffect, useState } from "react";
import { trpc } from "../utils/trpc";
import { getQueryKey } from "@trpc/react-query";
import { useQueryClient } from "@tanstack/react-query";

type Params = {
    deviceId: string,
    config?: ReadingConfigType,
    onDone?: (config?: ReadingConfigType) => void,
    readOnly: boolean
}

const schema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string"
        },
        "config": {
            "type": "object",
            "properties": {
                "address": {
                    "type": "string",
                },
                "measurand": {
                    "type": "string",
                    "enum": MeasurandValues
                },
                "unit": {
                    "type": "string",
                    "enum": UnitValues
                },
                "sourceType": {
                    "type": "string",
                    "enum": SourceTypeValue
                },
                "driver": {
                    "type": "string",
                    "enum": DriverValues
                },
                "dataType": {
                    "type": "string",
                    "enum": DataTypeValues
                },
                "chargePointId": {
                    "type": "string",
                },
                "connectorId": {
                    "type": "integer"
                },
                "phase": {
                    "type": "integer",
                    "enum": [1, 2, 3]
                }
            }
        }
    }
}

const uiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/name",
                    "label": "Reading Name"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/sourceType",
                    "label": "Measurand"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/measurand",
                    "label": "Source Type"
                },
            ]
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/driver",
                    "label": "Driver"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/dataType",
                    "label": "Data Type"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/unit",
                    "label": "Unit"
                },

            ]
        },
        {
            "type": "HorizontalLayout",
            elements: [
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/chargePointId",
                    "label": "CP ID"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/connectorId",
                    "label": "Connector ID"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/config/properties/phase",
                    "label": "Phase"
                },
            ]
        },
        {
            "type": "Control",
            "scope": "#/properties/config/properties/address",
            "label": "Address",
            "options": {
                "multi": true
              }
        },
    ]
}

export const ReadingConfig = ({ deviceId, config, onDone, readOnly }: Params) => {
    const [editedConfig, setConfig] = useState<ReadingConfigType | undefined>(config);
    // useEffect(() => {
    //     setConfig(config);
    // }, [config]);
    const queryClient = useQueryClient();
    const upsertM = trpc.manageCircuit.upsertCircuitDeviceMeter.useMutation({
        onSuccess: (r) => {
            const queryKey = getQueryKey(trpc.manageCircuit.getCircuitDeviceMeters);
            queryClient.invalidateQueries(queryKey);
            if (onDone) {
                onDone(editedConfig);
            }
        }
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} onClick={(e) => e.stopPropagation()}>
            <JsonForms
                schema={schema}
                uischema={uiSchema}
                data={editedConfig}
                readonly={readOnly}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={(e) => {
                    setConfig(e.data);
                }} />
            {!readOnly && <div style={{ alignSelf: 'end' }}>
                <Button onClick={(e) => {
                    if (onDone != undefined) {
                        onDone(editedConfig);
                    }
                }}>Cancel</Button>
                <Button onClick={(e) => {
                    if (editedConfig != undefined) {
                        upsertM.mutate({
                            deviceId: deviceId,
                            meterConfig: editedConfig
                        });
                    }
                }}>Save</Button>
            </div>}
        </div>
    )

}