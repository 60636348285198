import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Button } from "@mui/material";
import { ArrayElement } from "powerlinks-common";
import { useState } from "react";
import { RouterInputs, RouterOutputs, trpc } from "../../utils/trpc";
type CP = ArrayElement<RouterOutputs["manageCircuit"]["getCircuitChargePoints"]>;

type Params = {
    chargePoint: CP
}

const schema = {
    "type": "object",
    "properties": {
        "key": {
            "type": "string"
        },
        "value": {
            "type": "string"
        }
    }
}

const uiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/cpId",
            "label": "Charge Point ID"
        },
    ]
}
type UpdateConfigType = RouterInputs["cpDebug"]["updateOCPPConfig"];

export const UpdateConfig = ({ chargePoint }: Params) => {
    const [data, setData] = useState<UpdateConfigType>({ cpId: chargePoint.cpId, key: "", value: "" });
    const updateConfigM = trpc.cpDebug.updateOCPPConfig.useMutation();
    return (<div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>Update Configuration</div>
        <JsonForms
            schema={schema}
            data={data}
            renderers={materialRenderers}
            cells={materialCells}
            onChange={({ data }) => {
                setData(data);
            }} />
        <div>{JSON.stringify(updateConfigM.data)}</div>
        <div style={{ width: "100%", display: 'flex', justifyContent: 'end' }}>
            <Button onClick={async () => {
                console.log(data);
                const result = await updateConfigM.mutate(data);
                console.log("Update Config Result", result);
            }}>Update Config</Button>
        </div>
    </div>
    )

}